const SEO = [
	{
		page: "home",
		description:
			"A passionate software developer with 2 years of experience in creating web applications. Proficient in a wide array of modern technologies and frameworks, I specialize in crafting functional user interfaces. My dedication to staying updated		with the latest industry trends and best practices allows me to deliver robust and visually appealing solutions.",
		keywords: ["İsmet", "İsmet Ömer Koyuncu"],
	},

	{
		page: "about",
		description:
			"A passionate software developer with 2 years of experience in creating web applications. Proficient in a wide array of modern technologies and frameworks, I specialize in crafting functional user interfaces. My dedication to staying updated		with the latest industry trends and best practices allows me to deliver robust and visually appealing solutions.",
		keywords: ["İsmet", "İsmet Ömer Koyuncu"],
	},

	{
		page: "articles",
		description:
			"A passionate software developer with 2 years of experience in creating web applications. Proficient in a wide array of modern technologies and frameworks, I specialize in crafting functional user interfaces. My dedication to staying updated		with the latest industry trends and best practices allows me to deliver robust and visually appealing solutions.",
		keywords: ["İsmet", "İsmet Ömer Koyuncu"],
	},

	{
		page: "projects",
		description:
			"A passionate software developer with 2 years of experience in creating web applications. Proficient in a wide array of modern technologies and frameworks, I specialize in crafting functional user interfaces. My dedication to staying updated		with the latest industry trends and best practices allows me to deliver robust and visually appealing solutions.",
		keywords: ["İsmet", "İsmet Ömer Koyuncu"],
	},

	{
		page: "contact",
		description:
			"If you're interested in collaborating on a project, feel free to reach out to me. I'm always open to new ideas and opportunities.",
		keywords: ["İsmet", "İsmet Ömer Koyuncu"],
	},
];

export default SEO;
