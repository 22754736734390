import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import Yerlem from "../../assets/images/terra-yazilim.jpg";

import "./styles/works.css";

const Works = () => {
	const navigate = (url) => {
		window.open(url, "_blank");
	};

	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						<div
							onClick={() => navigate("https://www.yerlem.com/")}
							className="work"
							style={{ cursor: "pointer" }}
						>
							<img
								src={Yerlem}
								alt="Yerlem"
								className="work-image"
							/>
							<div className="work-title">
								Terra Software Informatics LLC.
							</div>
							<div className="work-subtitle">
								Software Engineer
							</div>
							<div className="work-duration">
								Feb 2022 - Present
							</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
