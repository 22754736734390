import React from "react";

function article_1() {
	return {
		date: "3 Jul 2022",
		title: "Keras ile Görüntü Sınıflandırma (Image Classification)",
		description:
			"TensorFlow ve Keras kullanarak bir görüntü sınıflandırma modeli oluşturmayı anlatıyor. Başlangıçta sinir ağları ve CNN'ler hakkında temel bilgiler veriliyor. Ardından, veri hazırlama, model oluşturma ve eğitme adımları detaylı bir şekilde açıklanıyor. Sonuç olarak, %84,8 doğrulukla çalışan bir model elde ediliyor.",
		keywords: [
			"Neural Network",
			"Konvolüsyonel Sinir Ağları ",
			"Görsel Sınıflandırma",

		],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">Content of article 1</div>
					<img
						src="https://picsum.photos/200/300"
						alt="random"
						className="randImage"
					/>
				</div>
			</React.Fragment>
		),
	};
}

const myArticles = [article_1];

export default myArticles;
